import styled from 'styled-components/macro';
import {
  IconClever,
  IconEnvelope,
  IconGoogle,
  IconIdBadge,
  RadioWithLabel,
} from '@perts/ui';
import { theme, useTerms } from 'components';

const LabelStyled = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const Spacer = styled.span`
  margin-right: ${theme.units.fieldPaddingInternal};
`;

export const RosterSignOnTypeFields = ({ setFieldValue, values }) => {
  const terms = useTerms();

  return (
    <>
      <RadioWithLabel
        name="portalType"
        label={
          <LabelStyled>
            <IconEnvelope />
            <Spacer />
            Email Address
          </LabelStyled>
        }
        value="email"
        checked={values.portalType === 'email'}
        onChange={() => setFieldValue('portalType', 'email')}
      />
      <RadioWithLabel
        name="portalType"
        label={
          <LabelStyled>
            <IconGoogle />
            <Spacer />
            Google (recommended)
          </LabelStyled>
        }
        value="google"
        checked={values.portalType === 'google'}
        onChange={() => setFieldValue('portalType', 'google')}
      />
      <RadioWithLabel
        name="portalType"
        label={
          <LabelStyled>
            <IconClever />
            <Spacer />
            Clever
          </LabelStyled>
        }
        value="clever"
        checked={values.portalType === 'clever'}
        onChange={() => setFieldValue('portalType', 'clever')}
      />
      <RadioWithLabel
        name="portalType"
        label={
          <LabelStyled>
            <IconIdBadge />
            <Spacer />
            {terms.studentIdPortalType}
          </LabelStyled>
        }
        value="student_id"
        checked={values.portalType === 'student_id'}
        onChange={() => setFieldValue('portalType', 'student_id')}
      />
    </>
  );
};
