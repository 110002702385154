import { useFormikContext } from 'formik';

import { Col, Input, Row, Show, Switch, TextField } from '@perts/ui';
import { stringIsRegex } from '@perts/util';
import { useTerms } from 'components';
import { getPortalMessageFromFormValues } from 'pages/Classes/shared';
import { RosterSignOnRuleFormValues } from './RosterSignOnRuleForm';
import { FieldsEmail } from './FieldsEmail';
import { FieldsGoogle } from './FieldsGoogle';
import { FieldsStudentId } from './FieldsStudentId';
import { SignOnPreview } from './SignOnPreview';

const switchLabel = (ruleEnabled, terms) => {
  const checked = Boolean(ruleEnabled);
  const indeterminate = ruleEnabled == null;

  const mixedSwitchLabel = (
    <span>{terms.classes} have mixed sign-on rules.</span>
  );
  const enabledSwitchLabel = (
    <span>
      <strong>Enabled</strong>: the rule below will be applied when{' '}
      {terms.participants.toLowerCase()} sign on to the survey.
    </span>
  );
  const disabledSwitchLabel = (
    <span>
      <strong>Disabled (not recommended)</strong>: Enable sign-on rule to tell{' '}
      {terms.participants.toLowerCase()} what account to use for the survey
    </span>
  );

  const label = indeterminate
    ? mixedSwitchLabel
    : checked
    ? enabledSwitchLabel
    : disabledSwitchLabel;

  return label;
};

export const RosterSignOnRuleFormFields = ({
  userIsAdmin = false,
  initialValues,
}) => {
  const terms = useTerms();
  const { values, touched, errors, isSubmitting, setFieldTouched, setValues } =
    useFormikContext<RosterSignOnRuleFormValues>();

  const signOnPreviewMessage =
    values.ruleEnabled === null
      ? '—' // With mixed settings, no single message makes sense.
      : values.portalMessage;

  const onChangeRuleEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValues = {
      ...values,
    };

    // Make the change.
    updatedValues.ruleEnabled = !values.ruleEnabled;

    if (updatedValues.ruleEnabled) {
      // The rule is being enabled, display a required error on the rule field.
      setFieldTouched('studentEmailEnding');
    } else {
      // The rule is being disabled; clear its field.
      updatedValues.studentEmailEnding = '';
    }

    // Message should contain more info when the rule is enabled; update it.
    updatedValues.portalMessage = getPortalMessageFromFormValues({
      portal_type: updatedValues.portalType,
      rule: updatedValues.studentEmailEnding,
      ruleSwitch: Boolean(updatedValues.ruleEnabled),
      studentIdMin: updatedValues.studentIdMin,
      studentIdMax: updatedValues.studentIdMax,
      studentIdTerm: terms.studentIdPortalType,
    });

    setValues(updatedValues);
  };

  const onChangeParticipantPattern = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const studentEmailEndingTrimmed = e.target.value.trim();

    const changingToRegexMode =
      userIsAdmin &&
      !stringIsRegex(initialValues.studentEmailEnding) &&
      stringIsRegex(studentEmailEndingTrimmed);

    const portalMessage = changingToRegexMode
      ? '' // reset the message
      : getPortalMessageFromFormValues({
          portal_type: values.portalType,
          rule: studentEmailEndingTrimmed,
          ruleSwitch: Boolean(values.ruleEnabled),
          studentIdMin: values.studentIdMin,
          studentIdMax: values.studentIdMax,
          studentIdTerm: terms.studentIdPortalType,
        });

    setValues({
      ...values,
      studentEmailEnding: studentEmailEndingTrimmed,
      portalMessage,
    });
  };

  const onChangeMinMax =
    (field) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedValues = { ...values, [field]: e.target.value };
      updatedValues.portalMessage = getPortalMessageFromFormValues({
        portal_type: updatedValues.portalType,
        rule: updatedValues.studentEmailEnding,
        ruleSwitch: Boolean(updatedValues.ruleEnabled),
        studentIdMin: updatedValues.studentIdMin,
        studentIdMax: updatedValues.studentIdMax,
        studentIdTerm: terms.studentIdPortalType,
      });

      setValues(updatedValues);
    };

  return (
    <>
      <Row>
        <Col shrink vAlign="center">
          <Input
            component={Switch}
            id="ruleEnabled"
            name="ruleEnabled"
            label={switchLabel(values.ruleEnabled, terms)}
            labelPlacement="end"
            disabled={isSubmitting}
            checked={values.ruleEnabled}
            indeterminate={values.ruleEnabled === null}
            onChange={onChangeRuleEnabled}
          />
        </Col>
      </Row>

      <Show when={values.portalType === 'clever'}>
        {/* TODO: Are these fields appropriate? */}
        <FieldsGoogle
          patternIsRegexMode={stringIsRegex(values.studentEmailEnding)}
          onChange={onChangeParticipantPattern}
        />
      </Show>

      <Show when={values.portalType === 'email'}>
        <FieldsEmail
          patternIsRegexMode={stringIsRegex(values.studentEmailEnding)}
          onChange={onChangeParticipantPattern}
        />
      </Show>

      <Show when={values.portalType === 'google'}>
        <FieldsGoogle
          patternIsRegexMode={stringIsRegex(values.studentEmailEnding)}
          onChange={onChangeParticipantPattern}
        />
      </Show>

      <Show when={values.portalType === 'student_id'}>
        <FieldsStudentId onChange={onChangeMinMax} />
      </Show>

      <Show when={userIsAdmin}>
        <Row>
          <Col>
            <Input
              id="portalMessage"
              name="portalMessage"
              label={`Sign-on prompt for ${terms.participants.toLowerCase()}`}
              labelPlacement="top"
              component={TextField}
              fullWidth
              disabled={!values.ruleEnabled || isSubmitting}
              error={
                touched.portalMessage &&
                errors.portalMessage &&
                values.ruleEnabled
                  ? errors.portalMessage
                  : ''
              }
              maxLength={200}
            />
          </Col>
        </Row>
      </Show>

      <SignOnPreview message={signOnPreviewMessage} />
    </>
  );
};
